import Router from "./router/Router";
import { Container } from "semantic-ui-react";
import { Navbar } from "./features/Shared/components/Navbar/Navbar";

import "./App.scss";

function App() {
	return (
		<Container fluid className="App">
			<Navbar />
			<Router />
		</Container>
	);
}

export default App;
