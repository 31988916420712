import { Route, Routes } from "react-router-dom";
import CompleteRegistration from "../features/Auth/components/CompleteRegistration/CompleteRegistration";

const Router = () => {
	return (
		<Routes>
			<Route index path="/*" element={<CompleteRegistration />} />
		</Routes>
	);
};

export default Router;
