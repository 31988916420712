import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationInfo, setPassword } from "../../../../store/auth";
import { Button, Form } from "semantic-ui-react";

import "./CompleteRegistration.scss";

const CompleteRegistration = () => {
	const errorCode = useSelector((state) => state.status.errorCode);

	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getRegistrationInfo(id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const formik = useFormik({
		initialValues: {
			password: "",
			repeatPassword: "",
		},
		validationSchema: Yup.object({
			password: Yup.string()
				.required("Contraseña es requerida")
				.min(8, "La contraseña debe tener al menos 8 caracteres")
				.max(16, "La contraseña debe tener máximo 16 caracteres")
				.oneOf([Yup.ref("repeatPassword")], "Las contraseñas no coinciden"),
			repeatPassword: Yup.string()
				.required("Contraseña es requerida")
				.min(8, "La contraseña debe tener al menos 8 caracteres")
				.max(16, "La contraseña debe tener máximo 16 caracteres")
				.oneOf([Yup.ref("password")], "Las contraseñas no coinciden"),
		}),
		onSubmit: (formData) => {
			dispatch(setPassword(`${id}`, `${formData.password}`));
			setTimeout(() => {
				window.location.href = "https://pp.atiempoplanea.mx/";
			}, 5000);
		},
	});

	return (
		<div className="complete-registration">
			{!errorCode ? (
				<Form onSubmit={formik.handleSubmit} className="login">
					<Form.Input
						type="password"
						placeholder="SuperSecretP455word"
						name="password"
						label="Introduzca su contraseña"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.password}
						error={formik.touched.password && formik.errors.password}
						className="login__input"
					/>
					<Form.Input
						type="password"
						placeholder="SuperSecretP455word"
						name="repeatPassword"
						label="Repita su contraseña"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.repeatPassword}
						error={
							formik.touched.repeatPassword && formik.errors.repeatPassword
						}
						className="login__input"
					/>
					<div>
						<Button
							type="submit"
							onClick={formik.handleSubmit}
							id="primary-button"
						>
							Enviar
						</Button>
					</div>
				</Form>
			) : (
				<div className="complete-registration__subtitle">Código inválido</div>
			)}
		</div>
	);
};

export default CompleteRegistration;
