import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";
import { setError, setMessage } from "../../../../store/status";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../../../assets/Logo.png";

import "./Navbar.scss";
import "react-toastify/dist/ReactToastify.css";

export const Navbar = () => {
	const error = useSelector((state) => state.status.error);
	const message = useSelector((state) => state.status.message);

	const dispatch = useDispatch();

	if (error) toast.warning(error);
	if (message) toast.success(message);

	useEffect(() => {
		setTimeout(() => {
			dispatch(setError(null));
			// dispatch(setErrorCode(null));
			dispatch(setMessage(null));
		}, 1000);
	}, [dispatch, error, message]);

	return (
		<div className="container">
			<Menu>
				<Menu.Item>
					<Image src={Logo} size="mini" />
				</Menu.Item>
				<Menu.Item position="right">
					<NavLink className="link" to={"https://pp.atiempoplanea.mx/"}>
						Iniciar sesión
					</NavLink>
				</Menu.Item>
			</Menu>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				// theme="colored" // TODO: Add theme
			/>
		</div>
	);
};
